import SocialProof from 'components/ckan/SocialProof.jsx'
import Hero from './Hero.jsx'
import Link from 'next/link.js'
import { Player } from "@lottiefiles/react-lottie-player";
import React, { useRef } from "react";
import Marquee from "react-fast-marquee";
import { Footer } from "components/Footer";
import Image from 'next/image'
import Testimonials from '../ckan/Testimonials.jsx';

export function MainSocialProof() {

  const logos = [

    {
      name: "Data.gov",
      src: "/assets/images/logos/socialproof/data.gov_-300x71.webp",
      url: "https://data.gov/",
      style: "h-16 max-h-16",
    },
    {
      name: "FedEx",
      src: "/assets/images/logos/socialproof/rsz_fedex-300x169.webp",
      url: "https://www.fedex.com",
      style: "max-h-8 lg:max-h-10",
    },
    {
      name: "The World Bank Group",
      src: "/assets/images/logos/socialproof/The_World_Bank_Group-300x63.webp",
      url: "https://www.worldbank.org/en/home",
      style: "max-h-24",
    },
    {
      name: "NHS",
      src: "/assets/images/logos/socialproof/NHS.webp",
      url: "https://www.nhs.uk/",
      style: "sm:hidden lg:block",
    },
    {
      name: "OECD",
      src: "/assets/images/logos/socialproof/OECD-300x76.webp",
      url: "https://www.oecd.org/",
      style: "max-h-14 hidden lg:block",
    },
  ];

  const logos2 = [
    {
      name: "NHS",
      src: "/assets/images/logos/socialproof/NHS.webp",
      url: "https://www.nhs.uk/",
      style: "h-9 w-12 hidden sm:block lg:hidden",
    },
    {
      name: "OECD",
      src: "/assets/images/logos/socialproof/OECD-300x76.webp",
      url: "https://www.oecd.org/",
      style: "h-8 max-h-8 hidden sm:block lg:hidden",
    },
  ];


  return (
    <div className="bg-transparent py-4 sm:py-16" >
      <div className="mx-auto max-w-7xl px-6 lg:px-8 -mb-6" tabIndex={0}>
        <div className="mx-8 grid max-w-lg grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 items-center gap-x-8 gap-y-8 sm:max-w-xl sm:gap-x-10 lg:mx-0 lg:max-w-none grayscale ">
          {logos.map((logo) => (
            <Link className="flex items-center justify-center" key={logo.src} href={logo.url}>
              <Image
                className={`bypass-filter w-auto h-auto ${logo.style}`}
                src={logo.src}
                alt={logo.name}
                height={36}
                width={79}
              />
            </Link>
          ))}
        </div>
        <div className="mt-8 mx-16 grid max-w-md grid-cols-2 lg:hidden sm:max-w-xl grayscale ">
          <Link aria-label={"NHS"} className="flex items-center w-full justify-center" href={"https://www.nhs.uk/"}>
            <Image
              className={`bypass-filter w-auto h-auto  h-9 w-12 hidden sm:block lg:hidden`}
              src={"/assets/images/logos/socialproof/NHS.webp"}
              alt={"NHS"}
              height={36}
              width={79}
            />
          </Link>
          <Link aria-label={"OECD"} className="flex items-center w-full justify-center" href={"https://www.oecd.org/"}>
            <Image
              className={`bypass-filter w-auto h-auto max-h-8 lg:max-h-10 hidden sm:block lg:hidden`}
              src={'/assets/images/logos/socialproof/OECD-300x76.webp'}
              alt={"OECD"}
              height={24}
              width={100}
            />
          </Link>
        </div>
      </div>
      <div className="mx-16 grid grayscale ">
        <Link aria-label={"OECD"} className="flex items-center w-full justify-center" href={"https://www.oecd.org/"}>
          <Image
            className={`bypass-filter w-auto h-auto max-h-8 lg:max-h-10 sm:hidden`}
            src={'/assets/images/logos/socialproof/OECD-300x76.webp'}
            alt={"OECD"}
            height={24}
            width={100}
          />
        </Link>
      </div>
    </div>
  );
}




export default function Index({ casestudies }) {

  const footerLinks = [
    {
      name: "Company",
      subItems: [
        { href: "/about", name: "About" },
        { href: "/jobs", name: "Jobs" },
        { href: "/contact", name: "Contact" },
        { href: "/impressum", name: "Impressum" },
        { href: "/privacy", name: "Privacy Policy" },
        { href: "/terms", name: "Terms" },
      ],
    },
    {
      name: "Solutions",
      subItems: [
        { href: "/solutions/open-data-portals", name: "Open Data Portals" },
        { href: "/solutions/data-catalogs", name: "Data Catalogs" },
        { href: "/solutions/internal-data-management", name: "Internal data management" },
        { href: "/solutions/data-engineering", name: "Data Engineering" },
        { href: "/solutions/data-strategy", name: "Data Strategy" },
      ],
    },
    {
      name: "CKAN Services",
      subItems: [
        { href: "/solutions/ckan/hosting", name: "CKAN Hosting" },
        { href: "/solutions/ckan/development", name: "CKAN Development" },
        { href: "/solutions/ckan/support", name: "CKAN Support" },
        { href: "/solutions/ckan/consultancy", name: "CKAN Consultancy" },
        { href: "/solutions/ckan/training", name: "CKAN Training" },
      ],
    },
    {
      name: "Explore",
      subItems: [
        { href: "/showcase", name: "Showcase" },
        { href: "/learn/faq", name: "FAQ" },
        { href: "/glossary", name: "Glossary" },
        { href: "/knowledge-base", name: "Knowledge Base" },
        { href: "/playbook", name: "Playbook" },
        { href: "/blog", name: "Blog" },
        { href: "/newsletter", name: "Newsletter" },
      ],
    },
    {
      name: "Other Projects",
      subItems: [
        { href: "https://ckan.org", name: "CKAN" },
        { href: "https://datahub.io", name: "DataHub.Io" },
        { href: "https://portaljs.org", name: "PortalJS" },
        { href: "https://frictionlessdata.io", name: "Frictionless Data" },
        { href: "https://flowershow.app", name: "Flowershow" },
        { href: "https://lifeitself.org", name: "Life Itself" },
        { href: "https://giftless.datopian.com", name: "Giftless" },
      ],
    },
  ]

  const AuthorConfig = {
    name: "Datopian",
    logo: "/assets/images/layout/datopian-logo-black.png",
    url: "https://www.datopian.com/",
  }

  const logos = [
    {
      name: "The World Bank Group",
      src: "/assets/images/The_World_Bank_Group-300x63.png",
      style: " bypass-filter xl:h-[40px] h-[40px]",
    },
    {
      name: "data.gov",
      src: "/assets/images/data.gov_-300x71.png",
      style: " bypass-filter xl:h-[30x] h-[30px]",
    },
    {
      name: "FedEx",
      src: "/assets/images/rsz_fedex-300x169.png",
      style: " bypass-filter xl:h-[40px] h-[40px]",
    },
    {
      name: "gov.uk",
      src: "/assets/images/gov-uk.webp",
      style: " bypass-filter xl:h-[23px] h-[23px]",
    },
    {
      name: "OECD",
      src: "/assets/images/OECD-300x76.png",
      style: " bypass-filter xl:h-[30px] h-[30px]",
    },
    {
      //look into the image src for this one
      name: "HDX",
      src: "/assets/images/HDX.png",
      style: " bypass-filter xl:h-[30px] h-[30px]",
    },
    {
      name: "nextgeoss",
      src: "/assets/images/nextgeoss.png",
      style: " bypass-filter xl:h-[23px] h-[23px]",
    },
    {
      name: "NHS",
      src: "/assets/images/NHS.png",
      style: " bypass-filter xl:h-[30px] h-[35px]",
    },
    {
      name: "US-Dept-of-Education",
      src: "/assets/images/US-Dept-of-Education.png",
      style: " bypass-filter xl:h-[45px] h-[45px]",
    },
    {
      name: " data.europa.eu",
      src: "/assets/images/dataeuropaeu.png",
      style: " bypass-filter xl:h-[25px] h-[30px]",
    },
    {
      name: "OpenData.swiss",
      src: "/assets/images/Opendata-Swiss.png",
      style: " bypass-filter xl:h-[30px] h-[35px]",
    },
    {
      name: "Open Data Northern Ireland",
      src: "/assets/images/Open-Data-Northern-Ireland.png",
      style: " bypass-filter xl:h-[40px] h-[30px]",
    },
    {
      name: "Open Data DK",
      src: "/assets/images/OpenData-DK.svg",
      style: " bypass-filter xl:h-[19px] h-[10px]",
    }
  ];

  const solutions = [{
    title: "Open Data Portals",
    brief: "Whether it's for the purpose of compliance, transparency or innovation, we work with governments, companies and cities to publish data properly.",
    icon: "/assets/icons/rocket.json",
    position: "h-24 w-24 scale-90 absolute -left-6 -top-6",
    link: "/solutions/open-data-portals"
  },
  {
    title: "Internal Data Management",
    brief: "We help you break down silos and create a seamless, integrated data ecosystem.",
    icon: "/assets/icons/ai.json",
    position: "h-24 w-24 absolute left-4 -top-10",
    link: "/solutions/internal-data-management"
  },
  {
    title: "CKAN Services",
    brief: "We are the creators, co-stewards, and one of the primary developers of CKAN. We make our CKAN expertise available to organizations worldwide.",
    icon: "/assets/icons/boat.json",
    position: "h-24 w-24 absolute right-4 -top-10",
    link: "/ckan"
  },
  {
    title: "Data Engineering",
    brief: "Tailor-made solutions for your specific industry and business needs.",
    icon: "/assets/icons/connection.json",
    position: "h-24 w-24 absolute -right-6 -bottom-6",
    link: "/solutions/data-engineering"
  },]

  const values = [
    {
      title: "We save you money",
      description: "We have a vast code base and a wealth of materials from our work on previous similar projects - this saves us time and you money.",
      icon: "/assets/icons/ewallet2.json",
      id: "value0"
    },
    {
      title: "We meet your unique needs",
      description: "We go beyond one-size-fits-all to give you a data solution that's crafted to be as unique as your business and its challenges.",
      icon: "/assets/icons/support.json",
      id: "value1"
    },
    {
      title: "We provide end-to-end support",
      description: "We can help in each step of the process - from crafting your data strategy and generating job stories, through implementation, to training and handover to your team members.",
      icon: "/assets/icons/tech-support.json",
      id: "value2"
    },
  ]
  const sortByDateCreated = casestudies.sort((a, b) => {
    return new Date(b.date) - new Date(a.date);
  });

  const recentStudies = sortByDateCreated.slice(0, 3)

  const dataPortals = [
    {
      title: "United States Government Open Data Portal",
      image: "/assets/images/layout/datagov.webp",
      href: "https://data.gov/",
      description: "Government Open Data Portal",
    },
    {
      title: "US Department of Education Open Data Platform",
      image: "/assets/images/layout/dept-ed2.webp",
      href: "https://data.ed.gov/",
      description: "Government Open Data Portal",
    },
    {
      title: "United Kingdom National Government Data Portal",
      image: "/assets/images/layout/datagovuk.webp",
      href: "https://data.gov.uk",
      description: "Government Open Data Portal",
    },

  ]

  const portalJS = [
    {
      name: 'Easily combine data from anywhere',
      description: 'Present data and content in one seamless site, pulling datasets from a DMS (e.g. CKAN) and content from a CMS (e.g. Wordpress) with a common internal API.',
    },
    {
      name: 'Everything you need, right out-of-the-box',
      description: 'PortalJS comes fully loaded with an extensive set of portal components. Catalog search, dataset showcase, blog - you name it, we have it.',
    },
    {
      name: 'Built for developers, by developers',
      description:
        'Uniting JavaScript, React, and Next.js, PortalJS provides a platform that is a familiar playground for developers.',
    },
    { name: 'Your portal, your way', description: 'Quickly add new routes, install themes, and employ standard CSS and React+CSS tooling. Design your portal to reflect your unique style and functionality needs.' },
    { name: 'Extend to excel', description: 'Need to add a custom feature? No problem. Quickly extend and develop or import your own React components to match your evolving needs.' },
    { name: 'A new era of data portal design', description: 'Our "decoupled" approach keeps the frontend/backend separate via an API. Ready for any backend, and with out-of-the-box support for CKAN. ' },
  ]

  const shorterPortalJS = [
    {
      name: 'Unify all data',
      description: 'Seamlessly unify data and content from any DMS (e.g. <a href="http://ckan.org" className="underline text-maingray"><span>CKAN</span></a>) or CMS with a common API.',
    },
    {
      name: 'Right out-of-the-box',
      description: 'Take advantage of an extensive set of ready-to-use portal components: catalog search, dataset showcase, blog & more.',
    },
    {
      name: 'Your portal, your way',
      description:
        'Customize with ease - add routes, implement themes, use CSS & React + CSS tools.',
    },
    {
      name: 'Extend to excel',
      description: 'Develop/import your own React components to match evolving needs.'
    },
  ]


  const ourWork = [
    {
      title: "UAE",
      image: "/assets/images/layout/fcsc_bsh.jpg",
      description: "Government Open Data Portal",
      position: "md:mt-8",
    },
    {
      title: "ODNI",
      image: "/assets/images/layout/odni_bsh.jpg",
      description: "Government Open Data Portal",
      position: "md:-mt-24",
    },
    {
      title: "Birmingham",
      image: "/assets/images/layout/brmnghm_bsh.jpg",
      description: "Government Open Data Portal",
      position: "md:mt-8",
    },
    {
      title: "OpenSpending",
      image: "/assets/images/layout/opnspndng_bsh.png",
      description: "OpenSpending Data Portal",
      position: "md:-mt-24",
    },
    {
      title: "FiveThirtyEight replica",
      image: "/assets/images/layout/538_bsh.png",
      description: "OpenSpending Data Portal",
      position: "md:-mt-24",
    },
  ]
  return (
    <section>
      <div className="bg-homeHero bg-no-repeat bg-heroPosition transition-all relative flex flex-col items-center justify-center !font-['Inter']">

        <div className=" max-w-7xl w-full relative pb-16">
          <Hero topTitle=""
            title=""
            subtitle="We're passionate about data. Passionate about its power to help us understand and shape our societies. And we want to democratize that power by giving more people, more access to high-quality data and tooling."
            ctaOffer="Let&apos;s Talk" ctaUrl="/contact" />


        </div>
        <MainSocialProof />
        <div className="max-w-7xl w-full relative z-20">

          <section className="flex flex-col mb-28 pt-16 px-8 justify-center ">
            <h2 className="main-top-title lg:max-w-md max-w-xs">
              WHO ARE WE?
            </h2>

            <div className="mt-16 grid grid-cols-1 md:grid-cols-2 items-center justify-center gap-x-4 gap-y-8 overflow-visible">

              <div id="highlight0" className="even-position h-full">
                <div className="flex flex-col gap-2 h-full">
                  <h3 className="h3 small-title">Creators of CKAN</h3>
                  <p className="main-text !text-sm max-w-md">Our team is proud to be led by the creator of <a href="http://ckan.org" className="underline text-maingray"><span>CKAN</span></a> himself, <a href="https://rufuspollock.com/" className="underline text-maingray"><span>Dr. Rufus Pollock</span></a>, president at Datopian. We are the creators of <a href="http://ckan.org" className="underline text-maingray"><span>CKAN</span></a>.</p>
                </div>
                <div className="flex justify-center items-center border-orange-400 rounded-full w-20 h-20 aspect-[1/1]">
                  <Player
                    className="h-24 w-24 scale-90"
                    autoplay
                    loop
                    src="/assets/icons/boat.json"
                  />
                </div>
              </div>
              <div id="highlight1" className="even-position h-full">
                <div className="flex flex-col gap-2">
                  <h3 className="h3 small-title">Pioneers in data</h3>
                  <p className="main-text !text-sm max-w-md">We are a pioneer in the data industry, with nearly two decades of expertise in developing cutting-edge data tools and applications. We built <a href="http://ckan.org" className="underline text-maingray"><span>CKAN</span></a>, <a href="https://frictionlessdata.io/" className="underline text-maingray"><span>Frictionless Data</span></a>, <a href="https://portaljs.org" className="underline text-maingray"><span>PortalJS</span></a>, <a href="https://datahub.io" className="underline text-maingray"><span>DataHub.io</span></a>, <a href="https://flowershow.app" className="underline text-maingray"><span>Flowershow</span></a>, and more.</p>
                </div>
                <div className="flex justify-center items-center border-orange-400 rounded-full w-20 h-20 aspect-[1/1]">
                  <Player
                    className="h-24 w-24 scale-90"
                    autoplay
                    loop
                    src="/assets/icons/verified-3.json"
                  />
                </div>
              </div>
              <div id="highlight2" className="even-position h-full">
                <div className="flex flex-col gap-2">
                  <h3 className="h3 small-title">Trusted by industry leaders</h3>
                  <p className="main-text !text-sm max-w-md">We&apos;ve worked for companies and organizations <a href="https://www.google.com" className="underline text-maingray"><span>Google</span></a>, the <a href="https://www.worldbank.org/" className="underline text-maingray"><span>World Bank</span></a>, and  <a href="https://www.fedex.com/" className="underline text-maingray"><span>FedEx</span></a>, and have successfully deployed dozens of projects for government, Fortune 500 enterprises and international NGOs.</p>
                </div>
                <div className="flex justify-center items-center border-orange-400 rounded-full w-20 h-20 aspect-[1/1]">
                  <Player
                    className="h-20 w-20"
                    autoplay
                    loop
                    src="/assets/icons/handshake.json"
                  />
                </div>
              </div>
              <div id="highlight3" className="even-position h-full">
                <div className="flex flex-col gap-2">
                  <h3 className="h3 small-title">Experts since 2004</h3>
                  <p className="main-text !text-sm max-w-md">We have built the world&apos;s first portals for publishing open data such as <a href="https://data.gov/" className="underline text-maingray"><span>data.gov</span></a> and <a href="https://data.gov.uk/" className="underline text-maingray"><span>data.gov.uk</span></a>. the world&apos;s leading data portal platform and as such, we&apos;ve been building data portals for longer than anyone else.</p>
                </div>
                <div className="flex justify-center items-center border-orange-400 rounded-full w-20 h-20 aspect-[1/1]">
                  <Player
                    className="h-24 w-24 scale-90"
                    autoplay
                    loop
                    src="/assets/icons/clock.json"
                  />
                </div>
              </div>

            </div>
          </section>

          <section className=' flex flex-col items-start px-8'>
            <h2 className="main-top-title max-w-xs">
              OUR SOLUTIONS
            </h2>
            <section className="mt-20 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-12 sm:gap-6 h-full hover:cursor-pointer">
              {solutions.map((solution) => (
                <Link href={solution.link} key={solution.link} className="group flex flex-col  p-6 shadow-lg text-start bg-orange-50 h-full hover:scale-105 transition-all shadow-lg  transition relative rounded-xl">
                  <Player
                    className={solution.position}
                    autoplay
                    loop
                    src={solution.icon}
                  />
                  <h3 className="mt-12 text-maingray text-xl sm:text-xl lg:text-2xl leading-tight font-bold sm:leading-tight w-full text-maingray row-start-2 group-hover:text-orange-400 transition-all">
                    {solution.title}
                  </h3>
                  <p className="pt-2 main-text row-span-2">
                    {solution.brief}
                  </p>
                  <div className="flex items-center gap-2 mt-auto pt-4">
                    <div className="small-title transition-all !text-orange-400">Read More</div>
                    <svg width="16" height="9" viewBox="0 0 30 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 7C0.447715 7 4.82823e-08 7.44772 0 8C-4.82823e-08 8.55228 0.447715 9 1 9L1 7ZM29.7071 8.70711C30.0976 8.31659 30.0976 7.68342 29.7071 7.2929L23.3431 0.928934C22.9526 0.53841 22.3195 0.53841 21.9289 0.928934C21.5384 1.31946 21.5384 1.95262 21.9289 2.34315L27.5858 8L21.9289 13.6569C21.5384 14.0474 21.5384 14.6805 21.9289 15.0711C22.3195 15.4616 22.9526 15.4616 23.3431 15.0711L29.7071 8.70711ZM1 9L29 9L29 7L1 7L1 9Z" fill="#fb923c" />
                    </svg>
                  </div>
                </Link>
              ))}
            </section>


            <Link href="/solutions" className="flex items-center gap-2 pt-8 hover:scale-105 transition-all">
              <div className="small-title transition-all !text-orange-400">Explore our solutions</div>
              <svg width="16" height="9" viewBox="0 0 30 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 7C0.447715 7 4.82823e-08 7.44772 0 8C-4.82823e-08 8.55228 0.447715 9 1 9L1 7ZM29.7071 8.70711C30.0976 8.31659 30.0976 7.68342 29.7071 7.2929L23.3431 0.928934C22.9526 0.53841 22.3195 0.53841 21.9289 0.928934C21.5384 1.31946 21.5384 1.95262 21.9289 2.34315L27.5858 8L21.9289 13.6569C21.5384 14.0474 21.5384 14.6805 21.9289 15.0711C22.3195 15.4616 22.9526 15.4616 23.3431 15.0711L29.7071 8.70711ZM1 9L29 9L29 7L1 7L1 9Z" fill="#fb923c" />
              </svg>
            </Link>

          </section>

          <section>
            <div className="showcase-component">
              <div className="showcase-casestudies">
                <Link href="/showcase/case-studies" className="grid grid-cols-3 grid-rows-3 h-full w-full gap-4 hover:scale-105 transition-all">

                  <div className="shadow-lg hover:border-t-16 border-orange-400 bg-gradient-to-b from-[#FFFDF8] to-orange-50 row-span-2 col-span-3 h-full w-full transition-all">
                    <div className="flex flex-col items-start h-full p-8">
                      <div className="mt-auto flex flex-col items-start">
                        <Player
                          className="h-20 w-20"
                          autoplay
                          loop
                          src="/assets/icons/presentation-2.json"
                        />
                        <h2 className="main-top-title max-w-[16rem]">
                          CASE STUDIES</h2>
                      </div>
                    </div>
                  </div>

                  {recentStudies.map((study) => (
                    <div key={study.image} className="shadow-lg bg-orange-400 row-start-3 row-span-1 h-full w-full">
                      <Image src={study.image} alt="" height='320' width='240' className=" object-cover w-full h-full bypass-filter" />
                    </div>
                  ))}
                </Link>

              </div>
              <div className="showcase-dataportals">
                <Link href="/showcase/data-portals" className="grid grid-cols-3 grid-rows-3 h-full w-full gap-4 hover:scale-105 transition-all  hover:border-t-16 lg:hover:border-t-0 lg:hover:border-b-16 border-orange-400">

                  {dataPortals.map((portal) => (
                    <div key={portal.image} className="shadow-lg bg-orange-400 h-full w-full">
                      <Image src={portal.image} height='320' width='240' alt={portal.title} className="w-full
                       h-full object-cover bypass-filter" />
                    </div>
                  ))}

                  <div className="shadow-lg bg-gradient-to-b from-[#FFFDF8] to-orange-50 row-span-2 col-span-3 h-full w-full order-first lg:order-last">
                    <div className="flex flex-col items-start h-full p-8">
                      <div className="mt-auto flex flex-col items-start">
                        <Player
                          className="h-20 w-20"
                          autoplay
                          loop
                          src="/assets/icons/menu.json"
                        />
                        <h2 className="main-top-title max-w-[16rem] ">
                          DATA PORTALS</h2>
                      </div>
                    </div>
                  </div>

                </Link>
              </div>
            </div>
          </section>

        </div>
      </div>

      <div className="lg:pt-8 bg-homeWork bg-center-top bg-no-repeat bg-cover relative flex flex-col items-center justify-center !font-['Inter'] overflow-visible pb-12 lg:pb-16">
        <div className="bg-transparent max-w-7xl w-full relative z-20">



          <section className="flex flex-col-reverse gap-8 md:gap-0 lg:my-0 my-32 px-16 lg:px-0 md:flex-row justify-center">
            <div className="flex flex-col items-center justify-center lg:w-1/2 gap-12 lg:h-[700px]">
              {values.map((value) => (
                <div className="flex justify-center items-center gap-7 hover:scale-105 transition-all transition-all hover:cursor-default" id={value.id} key={value.title}>
                  <div className="flex flex-col gap-2">
                    <h3 className="h3 small-title text-start lg:text-end">{value.title}</h3>
                    <p className="main-text text-start lg:text-end max-w-md">{value.description}</p>
                  </div>
                  <div className="flex justify-center items-center border-orange-400 rounded-full w-20 h-20 aspect-[1/1]">
                    <Player
                      className="h-20 w-20 scale-110"
                      autoplay
                      loop
                      src={value.icon}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="flex justify-center items-center w-1/2  ">
              <h2 id="valuecenter" className="flex justify-center lg:text-center main-top-title max-w-sm">
                WHY WORK WITH US?
              </h2>
            </div>

          </section>


        </div>
      </div>

      <div className="pb-24 flex flex-col items-center mb-28 pt-16 px-8 justify-center">
        <div className="bg-transparent max-w-7xl w-full relative z-20"><Testimonials /></div></div>

      <div className="relative flex flex-col items-center justify-center !font-['Inter'] z-20">

        <section className="!max-w-screen flex justify-center pb-24">

          <div className="portaljs-home"
          >

            <div className=" flex flex-col items-center justify-center max-w-8xl w-full">


              <section aria-labelledby="features-heading" className="relative flex">


                <div className="flex flex-col-reverse lg:grid lg:max-w-8xl lg:grid-cols-9 lg:gap-x-16 p-16 lg:px-6">
                  <ul className="lg:col-span-4 grid md:grid-cols-2 md:gap-y-4 gap-y-8 gap-x-12 content-center  md:mt-20 lg:mt-24  ourWork">
                    {ourWork.map((showCase, index) => (
                      <li key={index} className="caret-transparent  min-w-full shrink-0 group hover:scale-105 transition-all">
                        <Link href="https://portaljs.org" className="grid grid-cols-1 text-start">
                          <Image src={showCase.image} className={`aspect-[5/4] max-h-[20rem] w-full object-cover object-top shadow-lg group-hover:shadow-xl bypass-filter ${showCase.position}`} height='640' width='480' alt="Data Portal Image" />
                        </Link>
                      </li>
                    ))}
                  </ul>
                  <div className="lg:col-span-5 lg:col-start-5 hover:scale-105 transition-all flex flex-col w-full justify-center items-start pb-16 lg:pb-0">
                    <h3 className="medium-title">
                      Discover PortalJS - the JavaScript framework for data portals
                    </h3>
                    <p className="mt-4 main-text">
                      Rapidly build rich data portals with <a href="https://portaljs.org" className="underline text-maingray"><span>PortalJS</span></a>: an intuitive, highly customizable and highly extendable JavaScript-based framework offering speedy development, complete backend compatibility, and with out-of-the-box support for <a href="http://ckan.org" className="underline text-maingray"><span>CKAN</span></a>.
                    </p>

                    <div className="mt-10 grid grid-cols-1 md:grid-cols-2 gap-4 text-sm">
                      <div className="flex gap-4 md:gap-0">
                        <span className='md:hidden'>•</span>
                        <div>
                          <div className="font-medium small-title">Unify all data</div>
                          <div className="main-text">Seamlessly unify data and content from any DMS (e.g. <a href="http://ckan.org" className="underline text-maingray"><span>CKAN</span></a>) or CMS with a common API.</div>
                        </div>
                      </div>
                      <div className="flex gap-4 md:gap-0">
                        <span className='md:hidden'>•</span>
                        <div>
                          <div className="font-medium small-title">Right out-of-the-box</div>
                          <div className="main-text">Take advantage of an extensive set of ready-to-use portal components: catalog search, dataset showcase, blog & more.</div>
                        </div>
                      </div>
                      <div className="flex gap-4 md:gap-0">
                        <span className='md:hidden'>•</span>
                        <div>
                          <div className="font-medium small-title">Your portal, your way</div>
                          <div className="main-text">Customize with ease - add routes, implement themes, use CSS & React + CSS tools.</div>
                        </div>
                      </div>
                      <div className="flex gap-4 md:gap-0">
                        <span className='md:hidden'>•</span>
                        <div>
                          <div className="font-medium small-title">Extend to excel</div>
                          <div className="main-text">Develop/import your own React components to match evolving needs.</div>
                        </div>
                      </div>
                    </div>
                    <Link href="https://portaljs.org" aria-label="PortalJS" className="flex items-center gap-2 pt-8">
                      <div className="small-title transition-all !text-orange-400">PortalJS Website</div>
                      <svg width="16" height="9" viewBox="0 0 30 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 7C0.447715 7 4.82823e-08 7.44772 0 8C-4.82823e-08 8.55228 0.447715 9 1 9L1 7ZM29.7071 8.70711C30.0976 8.31659 30.0976 7.68342 29.7071 7.2929L23.3431 0.928934C22.9526 0.53841 22.3195 0.53841 21.9289 0.928934C21.5384 1.31946 21.5384 1.95262 21.9289 2.34315L27.5858 8L21.9289 13.6569C21.5384 14.0474 21.5384 14.6805 21.9289 15.0711C22.3195 15.4616 22.9526 15.4616 23.3431 15.0711L29.7071 8.70711ZM1 9L29 9L29 7L1 7L1 9Z" fill="#fb923c" />
                      </svg>
                    </Link>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>

        {/*<MoreFeatures />*/}

        <section className='pt-16 bg-homeExpert bg-expertPosition bg-no-repeat bg-[length:1440px_600px] pb-32 pt-16 relative w-screen max-w-screen overflow-hidden flex flex-col items-center justify-center' tabIndex={0}>
          <div className="max-w-2xl">
            <h3 className='text-center mx-auto medium-title font-["Inter"] text-white px-8'>Data management is complex. We make it <span className="bg-clip-text text-transparent bg-gradient-to-r from-orange-300 to-orange-600 inline-block">easy</span><span className="inline-block whitespace-nowrap">.</span></h3>
            <p className='text-center font-["Inter"] text-maingray mt-4 px-8 sm:px-0 mx-auto max-w-lg'>Get the answers you need today. Join thousands of happy customers.</p>
            <div className='flex flex-col  sm:flex-row mt-8 gap-y-8 sm:gap-x-4 w-full justify-center px-8 sm:px-0 mx-auto xl:max-w-4xl lg:max-w-3xl md:max-w-2xl max-w-sm'>
              <div className='btn-primary'><Link href="/contact">Email us</Link></div>
              <div className='btn-secondary'><Link href="https://calendar.app.google/LT4acVdKn3Cxm2MXA">Schedule a free call</Link></div>
            </div>
          </div>
          <div className="mt-16 -mb-12">
            <SocialProof />
          </div>
        </section>
      </div>


    </section>
  )
}
